import React, { useState, useEffect, useRef } from "react";
import { View, Text } from "react-native";
import colors from "../../../../config/colors";
import RangeDatePicker from "../Movements/Elements/RangeDataPicker";
import ComboPicker from "../Movements/Elements/ComboPicker";
import styles from "../../../../styles/Farmer/DataRangeStyle";
import { formatDate } from "../../../../utils/utils";
import { fetchHandleMaterials } from "../../../../axios/axiosConfig";
//import Toast from 'react-native-toast-message';


const DataRange = ({
    onDateRangeChange,
    getTranslation,
    language,
    onChangeFilters,
    bathtubs,
    selectedBathtub,
    setSelectedBathtub,
}) => {
    const isDateRangeSelected = useRef(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedDirection, setSelectedDirection] = useState("IN");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [materials, setMaterials] = useState([]);

    const initialMaterials = [
        { id: "Liquame", name: getTranslation("Liquame") },
        { id: "Letame", name: getTranslation("Letame") },
        { id: "DigestatoLiquido", name: getTranslation("Digestato liquido") },
        { id: "DigestatoSecco", name: getTranslation("Digestato separato") },
    ];

    useEffect(() => {
        const fetchMaterials = async () => {
            const response = await fetchHandleMaterials(true);
            if (response?.status === 200) {
                const fetchedIds = response.data;
                const filteredMaterials = initialMaterials.filter((material) =>
                    fetchedIds.includes(material.id)
                );
                /* temporary removed 
                const digestatoSecco = initialMaterials.find(
                  (material) => material.id === "DigestatoSecco"
                );
                if (
                  digestatoSecco &&
                  !filteredMaterials.find(
                    (material) => material.id === "DigestatoSecco"
                  )
                ) {
                  filteredMaterials.push(digestatoSecco);
                }*/
                setMaterials(filteredMaterials);
            } else {
                console.warn("fetchMaterials: response = ", response)
                // Toast.show({ da capire come fare, magari snackbar
                //     type: 'error', // Usa 'error' o 'info' se non vuoi personalizzare
                //     text1: 'Errore nella richiesta',
                // });
            }
        };

        fetchMaterials();
    }, []);

    const others = [
        { id: "IN", name: getTranslation("Entrato") },
        { id: "OUT", name: getTranslation("Uscito") },
    ];

    const handleDateRangeChange = (startDate, endDate) => {
        console.log("startDate, endDate", startDate, endDate)
        startDate = formatDate(startDate)
        endDate = formatDate(endDate)
        setStartDate(startDate);
        setEndDate(endDate);
        onDateRangeChange(startDate, endDate);
        isDateRangeSelected.current = true;

        handleSubfilters(
            selectedMaterial,
            selectedBathtub,
            selectedDirection,
            startDate,
            endDate
        );
    };

    const handleFilterChange = (filterName, value) => {
        if (filterName === "material") {
            setSelectedMaterial(value);
        } else if (filterName === "bathtub") {
            setSelectedBathtub(value);
        } else if (filterName === "direction") {
            setSelectedDirection(value);
        }

        handleSubfilters(
            filterName === "material" ? value : selectedMaterial,
            filterName === "bathtub" ? value : selectedBathtub,
            filterName === "direction" ? value : selectedDirection,
            startDate,
            endDate
        );
    };

    const handleSubfilters = (
        material,
        bathtub,
        direction,
        startDate,
        endDate
    ) => {
        onChangeFilters({ material, bathtub, direction, startDate, endDate });
    };

    return (
        <>
            <View
                style={[
                    styles.content,
                    { backgroundColor: colors.white, borderRadius: 16, marginTop: 10 },
                ]}
            >
                <View style={styles.pickerContainer}>
                    <Text style={styles.label}> {getTranslation("Periodo")}</Text>
                    <RangeDatePicker
                        onDateRangeChange={handleDateRangeChange}
                        getTranslation={getTranslation}
                        language={language}
                        isDateRangeSelected={isDateRangeSelected}
                        formatDate={formatDate}
                    />
                </View>

                {isDateRangeSelected && (
                    <View style={styles.pickerContainer}>
                        <Text style={styles.label}> {getTranslation("Materiale")}</Text>
                        <ComboPicker
                            items={materials}
                            filters={true}
                            plates={false}
                            onValueChange={(value) => handleFilterChange("material", value)}
                        />
                    </View>
                )}

                {selectedMaterial && selectedMaterial === "DigestatoLiquido" && (
                    <>
                        <View style={styles.pickerContainer}>
                            <Text style={styles.label}> {getTranslation("Vasca")}</Text>
                            <ComboPicker
                                field={"nameWithInfo"}
                                items={bathtubs}
                                filters={true}
                                plates={false}
                                onValueChange={(value) => handleFilterChange("bathtub", value)}
                            />
                        </View>

                        <View style={styles.pickerContainer}>
                            <Text style={styles.label}> {getTranslation("Tipo")}</Text>
                            <ComboPicker
                                items={others}
                                filters={true}
                                plates={false}
                                onValueChange={(value) => handleFilterChange("direction", value)}
                            />
                        </View>
                    </>
                )}
            </View>
        </>
    );
};

export default DataRange;
