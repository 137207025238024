import React, { useState, useEffect, useRef } from "react";
import { ScrollView, StyleSheet, View, Text } from "react-native";
import NewDigestateWithdrawal from "../../components/Shadows/Farmer/Movements/NewDigestateWithdrawal";
import OwnStorage from "../../components/Shadows/SharedDepositManager/Movements/OwnStorage";
import colors from "../../config/colors";
import { Snackbar } from "react-native-paper";
import { FontAwesome } from "@expo/vector-icons";
import { useRoute } from "@react-navigation/native";
import fonts from "../../config/fonts";

const MovementScreen = ({ getTranslation, user, formatDate }) => {
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarBGColor, setSnackbarBGColor] = useState("");
    const [isFromNewRequestCard, setIsFromNewRequestCard] = useState(false);

    const route = useRoute();

    // Create a ref to store the updateData function
    const updateDataRef = useRef(null);

    // Function to handle data fetching
    const fetchData = async () => {
        if (updateDataRef.current) {
            updateDataRef.current();
        }
    };

    useEffect(() => {
        if (route?.params?.from === "NewRequestCard") {
            setIsFromNewRequestCard(true);
        }
    }, [route]);

    const handleShowSnackbar = (message, color) => {
        setSnackbarMessage(message);
        setSnackbarBGColor(color);
    };

    const [showAlert, setShowAlert] = useState(false);

    async function handleAlert() {
        setShowAlert(true);
    }

    return (
        <>
            <ScrollView style={styles.container}>
                <OwnStorage
                    title={getTranslation("Stoccaggi digestato")}
                    onShowSnackbar={handleShowSnackbar}
                    getTranslation={getTranslation}
                    handleAlert={handleAlert}
                    formatDate={formatDate}
                    isFromNewRequestCard={isFromNewRequestCard}
                    setUpdateData={(updateData) => (updateDataRef.current = updateData)}
                />
                <NewDigestateWithdrawal
                    title={getTranslation("Inserimento nuovo prelievo digestato")}
                    onShowSnackbar={handleShowSnackbar}
                    getTranslation={getTranslation}
                    formatDate={formatDate}
                    fetchData={fetchData}
                />
            </ScrollView>
            <Snackbar
                visible={snackbarMessage !== ""}
                onDismiss={() => setSnackbarMessage("")}
                duration={3000}
                action={{
                    label: "OK",
                    labelStyle: { color: "white" },
                    onPress: () => setSnackbarMessage(""),
                }}
                style={{ backgroundColor: snackbarBGColor, color: "white" }}
            >
                {snackbarMessage}
            </Snackbar>
            <Snackbar
                visible={showAlert}
                onDismiss={() => setShowAlert(false)}
                style={{
                    backgroundColor: colors.violet,
                    zIndex: 9999,
                    position: "relative",
                    left: "50%",
                    top: "0",
                    transform: [{ translateX: "-50%" }, { translateY: "-50%" }],
                    width: 200,
                    height: 200,
                    justifyContent: "center",
                    alignItems: "center",
                    elevation: 9,
                }}
                action={{
                    icon: "close",
                    onPress: () => setShowAlert(false),
                    size: 50,
                    style: {
                        position: "absolute",
                        top: -76,
                        right: -35,
                        fontWeight: "bold",
                    },
                }}
            >
                <View
                    style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
                >
                    <FontAwesome
                        name="exclamation-triangle"
                        size={24}
                        color={colors.white}
                        style={{ marginBottom: 8 }}
                    />
                    <Text
                        style={{
                            color: colors.white,
                            fontFamily: fonts.regular,
                            fontSize: 19,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {getTranslation("Confermare capacità residua stoccaggio digestato")}
                    </Text>
                </View>
            </Snackbar>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 10,
        paddingVertical: 5,
        backgroundColor: colors.green,
    },
});

export default MovementScreen;
