import React, { useState, useEffect, useCallback } from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import styles from "../../../../styles/Farmer/OwnStorageStyle";
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { fetchBathtubsToConfirm } from "../../../../axios/axiosConfig";
import StorageCard from "../../Farmer/Movements/StorageCard";

const OwnStorage = ({
    title,
    onShowSnackbar,
    getTranslation,
    sharedDepositManager,
    handleAlert,
    isFromNewRequestCard,
    setUpdateData,
}) => {
    const [collapsed, setCollapsed] = useState(true);
    const [responseData, setResponseData] = useState([]);
    const [nestedCollapsed, setNestedCollapsed] = useState(false);
    const logger = require("../../../../axios/loggingConfig");
    const navigation = useNavigation();
    const route = useRoute();

    const fetchData = async () => {
        try {
            const userDataObject = JSON.parse(await AsyncStorage.getItem("userData"));
            const response = await fetchBathtubsToConfirm(userDataObject.landingView);
            if (response.status === 200) {
                const apiArray = response.data;
                setResponseData(apiArray);
                if (
                    response.data[0].alertMessage != null ||
                    response.data[1].alertMessage != null
                ) {
                    handleAlert();
                }
            }
        } catch (error) {
            logger.error(`Error on fetchBathtubsToConfirm() function in OwnStorage.js (${error})`);
        }
    };

    const updateData = async () => {
        try {
            const userDataObject = JSON.parse(await AsyncStorage.getItem("userData"));
            const response = await fetchBathtubsToConfirm(userDataObject.landingView);
            if (response.status === 200) {
                const apiArray = response.data;
                setResponseData(apiArray);
            }
        } catch (error) {
            logger.error(`Error on fetchBathtubsToConfirm() function in OwnStorage.js (${error})`);
        }
    };

    useEffect(() => {
        fetchData();
        setNestedCollapsed(false);
    }, []);

    useFocusEffect(
        useCallback(() => {
            updateData();
            setNestedCollapsed(false);
            const from = route.params?.from;
            if (from && isFromNewRequestCard) {
                setCollapsed(false);
                navigation.setParams({ from: undefined });
            }

            if (setUpdateData) {
                setUpdateData(updateData);
            }

            return () => {
                if (!isFromNewRequestCard) {
                    setCollapsed(true);
                }
            };
        }, [isFromNewRequestCard, route.params, navigation, setUpdateData])
    );

    const handleToggleCollapse = () => {
        if (nestedCollapsed) {
            setCollapsed((prev) => !prev);
            setNestedCollapsed(false);
        } else {
            setCollapsed(false);
        }
    };

    function handleToggleNestedCollapse() {
        setNestedCollapsed((prev) => !prev);
    }

    return (
        <>
            {collapsed && (
                <TouchableOpacity style={styles.header} onPress={handleToggleCollapse}>
                    <View style={styles.iconContainer}>
                        <Text style={styles.title}>{title}</Text>
                        <Image
                            source={require("../../../../images/Transportatore/stoccaggio-verde.png")}
                            style={{ width: 79, height: 71 }}
                        />
                    </View>
                </TouchableOpacity>
            )}
            {!collapsed &&
                responseData.map((data, index) => (
                    <TouchableOpacity key={index} onPress={handleToggleCollapse}>
                        <StorageCard
                            depositId={data.id}
                            title={title}
                            qta={data.quantity}
                            capacità={data.capacity}
                            bathtubName={data.name}
                            alertMessage={data.alertMessage}
                            onShowSnackbar={onShowSnackbar}
                            getTranslation={getTranslation}
                            handleToggleNestedCollapse={handleToggleNestedCollapse}
                            isFromNewRequestCard={isFromNewRequestCard}
                            fetchData={fetchData}
                        />
                    </TouchableOpacity>
                ))}
        </>
    );
};

export default OwnStorage;
